import React, { useState } from "react"
import { Link } from "gatsby"
import Button from "../../Button/index"
import Socail from "../../../assets/img/careers/share.svg"
import Styled from "./styled.module.css"
import ShareButtons from "../../ShareButtons/share.component"

function Jobdetail({ obj, setShowModal }) {
  const tags = ""

  return (
    <section id="jobdetail">
      <div className="container">
        <div className={Styled.sectionWrapper}>
          {/* <img alt="Social" className="hidden-upto-md" src={Socail}></img> */}
          <ShareButtons
            title={obj.title}
            url={`${process.env.GATSBY_ORIGIN}${obj.slug}`}
            twitterHandle={`${process.env.TWEET_VIA}`}
            // tags={`${tags}`}
          />
          <div className={Styled.wrapper}>
            <div className={Styled.summaryWrapper}>
              {/* <img alt="Social" className="hidden-from-md" src={Socail}></img> */}
              {/* <ShareButtons
                title={obj.title}
                url={`${process.env.GATSBY_ORIGIN}${obj.slug}`}
                twitterHandle={`${process.env.TWEET_VIA}`}
                // tags={`${tags}`}
              /> */}
              <div className={Styled.titleWrapper}>
                <Link to="/careers">
                  <h3 className={`${Styled.linkToCareers} xs-text bold`}>
                    Back to careers
                  </h3>
                </Link>

                <h3 className={Styled.updateDate}>Updated on {obj.date}</h3>
                <h1
                  className={Styled.title}
                  dangerouslySetInnerHTML={{ __html: obj.title }}
                ></h1>
                <h2
                  className={Styled.subtitle}
                  dangerouslySetInnerHTML={{ __html: obj.location }}
                ></h2>
                <Button size="medium" onClick={() => setShowModal(true)}>
                  <span>Apply now</span>
                </Button>
              </div>
            </div>
            <div className={Styled.jobTitle}>
              {obj.title && (
                <p>
                  <span>Position title:</span> {obj.title}
                </p>
              )}

              {obj.experience && (
                <p>
                  <span>Experience:</span> {obj.experience}
                </p>
              )}
              {obj.class && (
                <p>
                  <span>Class:</span> {obj.class}
                </p>
              )}

              {obj.department && (
                <p>
                  <span>Department:</span> {obj.department}
                </p>
              )}

              {obj.no_of_positions && (
                <p>
                  <span>No of positions:</span> {obj.no_of_positions}
                </p>
              )}

              {obj.qualification && (
                <p>
                  <span>Qualification:</span> {obj.qualification}
                </p>
              )}

              {obj.salary && (
                <p>
                  <span>Salary:</span> {obj.salary}
                </p>
              )}

              <p>
                <span>Job location:</span> {obj.location.split("-")[0]}{" "}
                {obj.location_note ? obj.location_note : ""}
              </p>
              {obj.shift && (
                <p>
                  <span>Work shift timing:</span> {obj.shift}
                </p>
              )}
              {obj.working_hours && (
                <p>
                  <span>Working hours:</span> {obj.working_hours}
                </p>
              )}
              <p>A division of Integra Corp, USA and Integra Ltd, UK</p>
            </div>
            <ul className={Styled.expectList}>
              <h1 className={`${Styled.expectTitle} sm-title bold`}>
                What we are expecting?
              </h1>
              <span dangerouslySetInnerHTML={{ __html: obj.expecting }}></span>
            </ul>
            <Button size="medium" onClick={() => setShowModal(true)}>
              <span>Apply now</span>
            </Button>
            <div className={Styled.policyWrapper}>
              <h1 className={`${Styled.expectTitle} sm-title bold`}>
                Hiring Policy
              </h1>
              <span
                dangerouslySetInnerHTML={{ __html: obj.hiring_policy }}
                className={`${Styled.policyText} xs-text`}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Jobdetail
