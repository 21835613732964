import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/"
import Whywork from "../components/Careers/Whywork"
import Lifeat from "../components/Careers/Lifeat"
import Jobdetail from "../components/Careers/Jobdetail"
import Form from "../components/Careers/Openings/form"

import { graphql } from "gatsby"

export default function Template({ data }) {
  const { markdownRemark } = data
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Helmet
        title={markdownRemark.frontmatter.page_title}
        meta={[
          {
            name: `description`,
            content: `${markdownRemark.frontmatter.meta_description}`,
          },
          {
            name: "keywords",
            content: `${markdownRemark.frontmatter.meta_keyword}`,
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={markdownRemark.frontmatter.page_title}
        />
        <meta
          property="og:description"
          content={markdownRemark.frontmatter.meta_description}
        />
        <meta
          property="og:image"
          content="https://www.globalintegra.com/integra-featured-image.png"
        />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta
          name="twitter:title"
          content={markdownRemark.frontmatter.page_title}
        />
        <meta
          name="twitter:description"
          content={markdownRemark.frontmatter.meta_description}
        />
        <meta
          name="twitter:image"
          content="https://www.globalintegra.com/integra-featured-image.png"
        ></meta>
      </Helmet>
      <Layout>
        <main>
          <Jobdetail
            obj={markdownRemark.frontmatter}
            setShowModal={setShowModal}
          />
          <Whywork />
          <Lifeat />
          {showModal ? <Form setShowModal={setShowModal} /> : null}
          {/* <Jobalert bgColor="Gray" /> */}
        </main>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD MMM YYYY")
        slug
        page_title
        meta_keyword
        meta_description
        title
        location
        location_note
        experience
        department
        shift
        working_hours
        no_of_positions
        class
        salary
        qualification
        expecting
        hiring_policy
      }
    }
  }
`
